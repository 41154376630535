<template>
  <div class="dialogMask">
    <div class="dialog">
      <div class="tit">{{ dialogTitle }}</div>
      <div class="contentBox">
        <div class="content content2" @click="switchOption(i)" :key="i" v-for="(item, i) in abnList">
          <div class="icon">
            <img
              v-if="item.check"
              src="../../assets/imgs/checkBlue.png"
              alt=""
            />
            <img v-else src="../../assets/imgs/normal.png" alt="" />
          </div>
          <span>{{ item.desc }}</span>
        </div>
      </div>
      <div class="bottom">
        <span @click="handleAbnCancel">{{ dialogCancel }}</span>
        <span @click="handleAbnOk" :style="{background: !confirmReturnCode ? '#CBD5FB' : ''}">{{ dialogEnter }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dialog",

  data() {
    return {
      check: "",
    };
  },

  props: {
    abnList: {
      type: Array,
      default: () => [],
    },
    dialogContent: {
      type: String,
      default: "确定提交吗？",
    },
    dialogTitle: {
      type: String,
      default: "提示",
    },
    dialogCancel: {
      type: String,
      default: "取消",
    },
    dialogEnter: {
      type: String,
      default: "确定",
    },
    confirmReturnCode: {
      // type: String,
      default: "",
    },
  },

  watch: {},

  mounted: function () {},

  created: function () {},

  methods: {
    handleChoose(value) {
      console.log("111", value);
      this.check = value;
    },
    handleAbnOk() {
      if (this.confirmReturnCode === '') return;//如果没有选择退库原因则return
      this.$emit("handleAbnOk");
    },
    handleAbnCancel() {
      this.$emit("handleAbnCancel");
    },
    switchOption(i){
      this.$emit("switchOption", i);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dialogMask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 99;
  .dialog {
    width: 100%;
    // height: 3.76rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #ffffff;
    border-radius: 0.32rem 0.32rem 0px 0px;
    .tit {
      font-size: 0.4rem;
      color: #222222;
      text-align: center;
      margin: 0.4rem 0.36rem;
      font-weight: bold;
    }
    .contentBox{
      max-height: 9.42rem;
      overflow: auto;
    }
    .content {
      font-size: 0.32rem;
      color: #222222;
      text-align: center;
      padding: 0 0.25rem;
      display: flex;

      .icon {
        height: 0.36rem;
        width: 0.36rem;
        margin-right: .16rem;
        img {
          height: 0.36rem;
          width: 0.36rem;
        }
      }

      &>span{
        text-align: left;
        overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
      }
    }
    .content2 {
      height: 1.32rem;
      background: #F6F7F8;
      border-radius: .08rem;
      display: flex;
      align-items: center;
      margin: .24rem .32rem 0;

      &:first-child{
        margin-top: 0;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 0.48rem;
      margin-bottom: 0.4rem;
      span {
        width: 3.15rem;
        height: 0.8rem;
        line-height: 0.8rem;
        text-align: center;
        font-size: 0.28rem;
        &:first-child {
          background: #ecedf2;
          border-radius: 0.48rem;
          color: #000000;
          font-weight: 600;
        }
        &:last-child {
          background: #436eff;
          border-radius: 0.48rem;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
