<template>
  <div class="card card_bg" @click="toOrderDetail(item)">
    <div class="card-header" @click="handleSelect(item)">
      <span class="order"
        >车号
        <span v-if="item.vinCode">{{ item.vinCode }}({{ item.vinId }})</span>
        <span v-else>{{ item.vinId }}</span></span
      >
      <span :class="'status ' + item.color">{{ item.expressStatusName }}</span>
    </div>
    <div
      class="card-middle"
      @click="handleSelect(item)"
      :class="showCode === item.packageNo ? 'show' : 'hide'"
    >
      <div class="li">
        <span>收货人</span>
        <span class="revPhoneText">
          <span v-if="item.privacyCheck === 1" class="privacyCheck">
            隐私面单
          </span>
          <span v-if="item.rcvPhone !== ''">
            <a
              :href="'tel:' + this.getPhoneNumber(item.rcvPhone)"
              class="flexBox"
              @click.stop
              v-if="this.getVersion()"
            >
              <span>{{ item.rcvPhone }}</span>
              <img class="revPhone" src="../../assets/imgs/revPhone.png" />
            </a>
            <span v-else class="flexBox">
              <span>{{ item.rcvPhone }}</span>
              <img
                class="revPhone"
                src="../../assets/imgs/revPhone.png"
                @click.stop="handleAction(item)"
              />
            </span>
          </span>
          <span v-else>无</span>
        </span>
      </div>
      <div
        class="li address"
        v-if="item.expressStatus === 4 && item.putAwayTime"
      >
        <span class="addressTitle">上架时间</span>
        <span class="addressText" :class="{ cabinPass: item.highLight }">{{
          item.putAwayTime
        }}</span>
      </div>
      <div class="li address">
        <span class="addressTitle">详细地址</span>
        <span class="addressText">{{ item.rcvAddressDetail }}</span>
      </div>
      <div class="li address">
        <span class="addressTitle">收货点</span>
        <span class="addressText">{{ item.rcvStationName }}</span>
      </div>
      <div class="li" v-if="item.expressStatus !== 5">
        <span>取件码</span>
        <span class="cabinPass">{{ item.cabinPass }}</span>
      </div>
      <div class="li">
        <span>快递单号</span>
        <span>
          <span class="secondSend" v-if="item.secondaryCheck">二次配送</span>
          {{ item.expressNo }}
        </span>
      </div>
      <div class="li">
        <span>订单号</span>
        <span>{{ item.packageNo }}</span>
      </div>
      <div class="li">
        <span>包裹数量</span>
        <span>{{ item.courierCount }}件</span>
      </div>
      <div class="li">
        <span>快递公司</span>
        <span>{{ item.expressCompany }}</span>
      </div>

      <div class="li" v-if="[0, 1].includes(item.expressStatus)">
        <span>下单时间</span>
        <span class="">{{ item.createDate }}</span>
      </div>
      <div class="li" v-if="[2, 3, 4, 5].includes(item.expressStatus)">
        <span>到站时间</span>
        <span>{{ item.arrivalTime }}</span>
      </div>
      <div class="li" v-if="item.expressStatus === 3">
        <span>签收时间</span>
        <span>{{ item.signTime }}</span>
      </div>
      <div class="li" v-if="[4, 5].includes(item.expressStatus)">
        <span>滞留时间</span>
        <span>{{ item.holdUpTime }}</span>
      </div>
      <div class="li" v-if="item.expressStatus === 5">
        <span>线下配送时间</span>
        <span>{{ item.offlineTime }}</span>
      </div>

      <div class="li">
        <span>投放格口</span>
        <span class="cabinCode">{{ item.cabinCode }}格口</span>
      </div>
    </div>
    <div
      class="card-action"
      :class="showCode === item.packageNo ? 'displayNone' : ''"
    >
      <span @click.stop="showAll(item.packageNo || '')">
        <span>展开</span>
        <img src="../../assets/imgs/showAll.png" alt="" />
      </span>
    </div>
    <div
      class="card-bottom"
      @click="handleSelect(item)"
      v-if="item.expressStatus === 4"
      :class="{ start: selectable }"
    >
      <template v-if="selectable">
        <img
          v-if="selectedMap[item.id]"
          src="../../assets/imgs/selected.png"
          alt=""
        />
        <img v-else src="../../assets/imgs/unselected.png" alt="" />
      </template>
      <template v-else>
        <span class="canceled" @click.stop="secondSend(item)"> 二次配送 </span>
        <span class="canceled" @click.stop="offlineDelivery(item)"
          >线下配送</span
        >
      </template>
    </div>
    <!--已投柜-->
    <div
      class="card-bottom"
      @click="handleSelect(item)"
      v-if="installed"
      :class="{ start: selectable }"
    >
      <!-- <span
        class="canceled red"
        @click.stop="cancelDelivery(item)"
        v-if="item.secondaryCheck"
      >
        取消配送
      </span> -->
      <template v-if="selectable">
        <img
          v-if="selectedMap[item.id]"
          src="../../assets/imgs/selected.png"
          alt=""
        />
        <img v-else src="../../assets/imgs/unselected.png" alt="" />
      </template>
      <template v-else>
        <span class="canceled modify" @click.stop="modifyStation(item)"
          >修改收货点</span
        >

        <span class="canceled red" @click.stop="cancelDelivery(item)">
          取消配送
        </span>
      </template>
      <!-- <span class="canceled del" @click.stop="deleteOrder(item)" v-else>
        删除订单
      </span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "NewOrderCard",

  data() {
    return {
      showCode: "",
    };
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    installed: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    }, // 多选
    selectedMap: {
      type: Object,
      default: () => ({}),
    }, // 多选已选中的值
  },

  watch: {},

  mounted: function () {},

  created: function () {},

  methods: {
    modifyStation(item) {
      this.$emit("onmodifyStation", item);
    },
    handleSelect(item) {
      if (!this.selectable) return;
      this.$emit("onselect", item);
    },
    getPhoneNumber(phone) {
      if (phone.includes("-")) {
        return phone.split("-")[0];
      } else {
        return phone;
      }
    },
    getVersion() {
      if (this.isAndroid()) {
        return false;
      } else {
        const ios_ver = this.ios_ver();
        return this.versionStringCompare(ios_ver, "14.4.0");
      }
    },
    versionStringCompare(preVersion = "", lastVersion = "") {
      var sources = preVersion.split(".");
      var dests = lastVersion.split(".");
      var maxL = Math.max(sources.length, dests.length);
      let result = false;
      for (let i = 0; i < maxL; i++) {
        let preValue = sources.length > i ? sources[i] : 0;
        let preNum = isNaN(Number(preValue))
          ? preValue.charCodeAt()
          : Number(preValue);
        let lastValue = dests.length > i ? dests[i] : 0;
        let lastNum = isNaN(Number(lastValue))
          ? lastValue.charCodeAt()
          : Number(lastValue);
        if (preNum < lastNum) {
          result = false;
          break;
        } else if (preNum > lastNum) {
          result = true;
          break;
        }
      }
      return result;
    },
    // IOS版本号判断
    ios_ver() {
      const ua = navigator.userAgent.toLowerCase();
      return ua
        .match(/cpu iphone os (.*?) like mac os/)[1]
        .replaceAll("_", ".");
    },
    // 判断安卓
    isAndroid() {
      return /(Android)/i.test(navigator.userAgent);
    },
    // 判断是否IOS
    ios() {
      return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
    },
    showAll(code) {
      this.showCode = code;
    },
    toOrderDetail(data) {
      //   window.location.href = `neolixjs://orderDetails?orderCode=${data.packageNo}`;
    },

    offlineDelivery(data) {
      this.$emit("offlineDelivery", data);
    },
    secondSend(item) {
      this.$emit("secondSend", item);
    },
    handleAction(item) {
      this.$emit("toPhone", item);
    },
    //取消配送
    cancelDelivery(item) {
      this.$emit("cancelDelivery", item);
    },
    //删除订单
    deleteOrder(item) {
      console.log("删除订单");
      this.$emit("deleteOrder", item);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card_bg {
  @include background_color("card_bg");
}

.card {
  width: calc(100% - 0.6rem);
  padding: 0.12rem 0.3rem;
  margin: 0.3rem;
  border-radius: 0.24rem;
  .card-action {
    width: 100%;
    height: 0.32rem;
    line-height: 0.32rem;
    margin: 0.12rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    span {
      font-size: 0.24rem;
      color: #9798ab;
      letter-spacing: 0;
      font-weight: 400;
    }
    img {
      width: 0.2rem;
      height: 0.2rem;
      float: right;
      margin-top: 1px;
      margin-left: 0.08rem;
    }
  }
  .card-header {
    width: 100%;
    height: 0.64rem;
    line-height: 0.64rem;

    .order {
      font-size: 0.32rem;
      color: #000000;
      font-weight: 800;
      float: left;
    }
    .status {
      font-size: 0.28rem;
      float: right;
      font-weight: 600;
    }
    .cFC7E36 {
      color: #fc7e36;
    }
    .c436EFF {
      color: #436eff;
    }
    .c000000 {
      color: #000000;
    }
    .c9798AB {
      color: #9798ab;
    }
    .c00CB95 {
      color: #00cb95;
    }
    .cFF391B {
      color: #ff391b;
    }
  }

  .show {
    height: auto;
  }
  .hide {
    height: 182px;
    overflow: hidden;
  }
  .displayNone {
    display: none;
  }
  .card-middle {
    .flexBox {
      display: flex;
      align-items: center;
    }
    .li {
      font-size: 0.28rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 20px;
      padding: 3px 0;
      > :first-child {
        color: #9798ab;
      }
      > :last-child {
        color: #000000;
      }
      span a {
        text-decoration-line: none;
        color: #000000;
      }
      span i {
        font-style: normal;
        font-size: 0.24rem;
      }
      .revPhone {
        width: 20px;
        height: 20px;
        margin-left: 0.12rem;
      }
      .revPhoneText {
        display: flex;
        align-items: center;
      }
    }
    .address {
      align-items: baseline;
      padding: 0;
      line-height: 26px;
    }
    .addressTitle {
      margin-right: 0.32rem;
      flex: 1;
    }
    .addressText {
      text-align: right;
      line-height: 26px;
      flex: 4;
    }
    .cabinPass {
      color: #ff391b !important;
      font-weight: 600;
    }
    .privacyCheck {
      background: #fc7e36;
      border-radius: 4px;
      font-size: 0.24rem;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
      padding: 0.02rem 0.08rem;
      margin-right: 0.06rem;
    }
    .cabinCode {
      color: #436eff !important;
      font-weight: 600;
    }
    .secondSend {
      background: #00cb95;
      border-radius: 4px;
      font-family: PingFangSC-Regular;
      font-size: 0.24rem;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
      padding: 0.02rem 0.08rem;
      margin-right: 0.06rem;
    }
  }

  .card-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.3rem;
    padding-bottom: 0.12rem;
    padding-top: 0.28rem;
    margin-top: 0.08rem;
    border-top: 1px solid #ebebeb;
    height: 0.64rem;
    overflow: hidden;
    box-sizing: content-box;
    &.start {
      justify-content: flex-start;
    }
    img {
      width: 0.36rem;
      height: 0.36rem;
    }
    span,
    a {
      padding: 0.11rem 0.24rem;
      border-width: 0.01rem;
      border-style: solid;
      border-radius: 0.3rem;
      font-size: 0.26rem;
      font-weight: 500;
      margin-left: 0.24rem;
      text-decoration: none;
      -webkit-tap-highlight-color: transparent;
      [data-theme="light"] &:active {
        background: #d9d9d9;
      }
    }

    .canceled {
      background: #ffffff;
      border: 0.01rem solid rgba(67, 110, 255, 1);
      color: #436eff;
      font-weight: 500;
      border-radius: 0.32rem;

      &.red {
        color: #ff391b;
        border: 1px solid rgba(255, 57, 27, 1);
      }

      &.modify {
        color: #436eff;
        border: 1px solid #436eff;
      }

      // &.del {
      //   color: #000000;
      //   border: 1px solid rgba(213, 214, 222, 1);
      // }
    }
  }
}
</style>
