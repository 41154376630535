<!--
 * @Author: zhaohuaiyuan zhaohuaiyuan@neolix.cn
 * @Date: 2022-07-27 17:48:11
 * @LastEditors: zhaohuaiyuan zhaohuaiyuan@neolix.cn
 * @LastEditTime: 2022-08-01 15:16:37
 * @FilePath: /neolixapph5/src/views/MyOrderList/EmptyData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="emptyData">
    <img class="noOrder" src="../../assets/imgs/no.png" />
    <div class="noTXT">{{this.msg}}</div>
  </div>
</template>

<script>
export default {
  name: "NoData",

  date() {
    return {
      userInfo: {},
    };
  },

  props: {
    msg: {
      type: String,
      default: '暂无订单',
    },
  },

  watch: {},

  mounted: function () {},

  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.emptyData {
  width: 100%;
  height: calc(100% - 0.8rem);
  padding: 0 0.3rem 0.2rem;
  position: absolute;
  // @include background_color("title_bg");

  .noOrder {
    width: 3.2rem;
    height: 3.2rem;
    margin: 2.4rem auto 0;
    display: block;
  }
  .noTXT {
    text-align: center;
    font-size: 0.36rem;
    font-size: 0.32rem;
    color: #222222;
  }
}
</style>
