<template>
  <div class="searchContain bg" ref="contains">
    <!-- 搜索 -->
    <div
      class="searchPanel"
      :style="from === 'myOrderList' ? 'height: 1.92rem' : 'height: 0.85rem'"
    >
      <img
        class="searchBack"
        @click="handleSearchCancel"
        src="../../assets/imgs/goback.png"
        alt=""
      />
      <div class="searchSelect" @click="handleSelectValue">
        <span class="selectValue">{{ selectValue }}</span>
        <img class="searchUp" src="../../assets/imgs/upSelect.png" alt="" />
        <PopSelect
          :styleObj="style"
          :selectList="selectList"
          :triangle="triangle"
          :showFlag="showFlag"
          @handleSelect="handleSelectSet"
          @handleClose="handleScreen"
        />
      </div>
      <Tab
        v-if="from === 'myOrderList'"
        @handleTab="handleTab"
        :defaultTab="queryStatus"
        :tabList="tabList"
      />
      <div class="searchBarInner">
        <div class="inputWrapper">
          <img
            class="searchIcon"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/search.png"
            alt=""
          />
          <input
            type="text"
            v-model="value"
            ref="plateRef"
            :placeholder="placeholderValue"
            @keyup.enter="handleSearchEnter"
          />
          <img
            v-if="showClear"
            @click="value = ''"
            class="clearIcon"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/clear.png"
            alt=""
          />
        </div>
      </div>
      <div class="cancelBtn" @click="handleSearchEnter">搜索</div>
    </div>
    <div class="web" ref="refHeight">
      <div v-if="orderList.length != 0" class="info">
        <span class="space"></span>
        <span class="total">
          共
          <span class="totalNumber">{{ total }}</span>
          单
        </span>
        <span class="space">
          <template v-if="from === 'installedOrderlist' || showSelector">
            <span
              class="multipleSelect"
              v-if="multipleSelect"
              @click="enableMulipleSelect(false)"
              >取消</span
            >
            <span
              class="multipleSelect"
              v-else
              @click="enableMulipleSelect(true)"
              >批量选择</span
            >
          </template>
        </span>
      </div>
      <!-- 空数据 -->
      <NoData v-if="emptyDataFlag" />
      <!-- 有数据 -->
      <template v-else>
        <NewOrderCard
          :item="item"
          v-for="item in orderList"
          :key="item.orderCode"
          :installed="from === 'installedOrderlist'"
          :selectable="multipleSelect"
          :selectedMap="selectedMap"
          @onselect="handleItemSelect"
          @secondSend="handleSecondSendOrder"
          @offlineDelivery="handleOfflineDelivery"
          @toPhone="handlePhone"
          @onmodifyStation="handleItemModifyStation"
          @cancelDelivery="cancelDelivery"
        />
      </template>
    </div>
    <!-- 评价 -->
    <Dialog
      v-if="showDialog"
      :showClose.sync="showDialogClose"
      :dialogContent="dialogContent"
      :dialogTitle="dialogTitle"
      :dialogCancel="dialogCancel"
      :dialogEnter="dialogEnter"
      @handleOk="handleOk"
      @handleCancel="handleCancel"
    />
    <DialogAbn
      v-if="showDialogAbn"
      :abnList="abnList"
      :dialogTitle="dialogTitle"
      :dialogCancel="dialogCancel"
      :dialogEnter="dialogEnter"
      :confirmReturnCode="confirmReturnCode"
      @handleAbnOk="handleOk"
      @handleAbnCancel="handleCancel"
      @switchOption="switchOption"
    />
    <div
      v-if="multipleSelect && orderList.length !== 0"
      class="multipleSelectPannel"
    >
      <div>
        <img
          @click="handleSelectAll(false)"
          v-if="selectAll"
          src="../../assets/imgs/selected.png"
          alt=""
        />
        <img
          @click="handleSelectAll(true)"
          v-else
          src="../../assets/imgs/unselected.png"
          alt=""
        />
        <div>
          已选
          <div class="colorfulNumber">{{ selectedCount }}</div>
          <!-- <span class="colorfulNumber">{{ selectedCount }}</span> -->
          单
        </div>
      </div>
      <div v-if="from === 'installedOrderlist'">
        <div
          class="modifyBtn"
          :class="{ disabled: selectedCount === 0 }"
          @click="modifyMultipleStation"
        >
          修改收货点
        </div>
        <div
          @click="cancelMultipleDelivery"
          :class="{ disabled: selectedCount === 0 }"
          class="cancelBtn"
        >
          取消配送
        </div>
      </div>
      <div v-else>
        <div
          class="secondary"
          :class="{ disabled: selectedCount === 0 }"
          @click="deliverMultipleSecondary"
        >
          二次配送
        </div>
        <div
          @click="deliverMultipleOffline"
          :class="{ disabled: selectedCount === 0 }"
          class="offline"
        >
          线下配送
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import NewOrderCard from './NewOrderCard.vue';
import DialogAbn from './DialogList.vue';
import NoData from './NoData.vue';
import Dialog from '@/components/Dialog.vue';
import PopSelect from './PopSelect.vue';
import Tab from './Tab.vue';
import { Toast } from 'vant';
import {
  secondDelivery,
  offlineDelivery,
  selectPageList,
  getAbnList,
  justOpenDoor,
} from '@/api/api';
import { rightList } from '@/config/index';
import NDialog from '@/components/NDialog';
import {
  batchSecondDelivery,
  batchOfflineDelivery,
  batchCancelDelivery,
} from '@/api/apiv2';
import { modifyDeliveryStation } from '@/utils/index';

export default {
  name: 'searchList',
  props: {
    queryObject: {
      type: Object,
      default: () => {
        return {
          queryStatus: '',
          privacyCheck: [],
        };
      },
    },
    from: {
      type: String,
      default: 'myOrderList',
    },
    selectList: {
      type: Array,
      default: () => [
        {
          key: 4,
          value: '车号',
        },
        {
          key: 2,
          value: '手机号',
        },
        {
          key: 3,
          value: '收货点',
        },
        {
          key: 1,
          value: '快递单号',
        },
      ],
    },
  },
  components: {
    NewOrderCard,
    NoData,
    Dialog,
    PopSelect,
    DialogAbn,
    Tab,
  },
  computed: {
    showClear() {
      return this.value !== '';
    },
    selectAll() {
      const len = Object.keys(this.selectedMap).length;
      return len && len === this.orderList.length;
    },
    selectedCount() {
      return Object.keys(this.selectedMap).length;
    },
  },
  data() {
    return {
      value: '',
      selectValue: '车号',
      selectValueKey: 4,
      placeholderValue: '请输入车号',
      orderList: [],
      tabList: rightList,
      emptyDataFlag: true,
      commentedShow: false,
      showFlag: false,
      scrollTop: 0,
      queryStatus: this.queryObject.queryStatus, // 0-全部 1-进行中  2-已取消 4-已完成
      pageNum: 1,
      pageSize: 100,
      showDialog: false,
      dialogType: 'cancelOrder',
      dialogContent: '',
      total: 0,
      style: {
        box: {
          position: 'absolute',
          top: '0.9rem',
          left: '0.2rem',
        },
        triangle: {
          position: 'absolute',
          top: '0.78rem',
          left: '1.6rem',
        },
      },
      triangle: 'left',
      dialogCancel: '取消',
      dialogEnter: '确定',
      secondSendOrderCode: '', // 二次配送单号
      openOrderCode: '',
      openVin: '',
      timer: null,
      orderDetail: {},
      abnList: [],
      showDialogAbn: false,
      confirmReturnCode: '', //退库原因选项的code
      confirmReturnDesc: '', //退库原因描述
      multipleSelect: false,
      selectedMap: {}, // 已选中的快递
      showDialogClose: true,
      showSelector: false, // 是否显示多选 ⭐一个bug引起的逻辑，见commit message
      enableClick: true, // 二次派送按钮锁定
    };
  },

  mounted: function () {
    this.$refs['plateRef'].focus();
  },

  created: function () {
    // this.getSelectPageList();
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    handleItemModifyStation(item) {
      const {
        vehicleVin: vin,
        vinId,
        rcvStationId: stationId,
        rcvStationName: stationName,
        id,
        expressCompany: expressName,
        expressCompanyId,
        rcvLat,
        rcvLng,
      } = item;
      modifyDeliveryStation(
        JSON.stringify({
          multiple: 0,
          list: [
            {
              vin,
              vinId,
              stationId,
              stationName,
              id,
              expressName,
              expressCompanyId,
              rcvLat,
              rcvLng,
            },
          ],
        })
      );
    },
    async cancelDelivery(item) {
      const message = item.secondaryCheck
        ? '确定后，二次配送的订单状态将变为【异常件】，格口不会打开'
        : '确定后，请至打开的格口中取走快包裹';
      const status = await NDialog.confirm({
        title: '确定取消配送吗？',
        message,
      });
      if (status === 'confirm') {
        const res = await batchCancelDelivery({ ids: [item.id] });
        if (res === null) {
          Toast({
            message: '操作成功',
            className: 'response-toast',
          });
        }
        this.initMultipleSelect();
        this.getSelectPageList();
      }
    },
    modifyMultipleStation() {
      if (!this.selectedCount) return;
      const data = {
        multiple: 1,
        list: Object.values(this.selectedMap).map((item) => ({
          vin: item.vehicleVin,
          vinId: item.vinId,
          stationId: item.rcvStationId,
          stationName: item.rcvStationName,
          id: item.id,
          expressName: item.expressCompany,
          expressCompanyId: item.expressCompanyId,
          rcvLat: item.rcvLat,
          rcvLng: item.rcvLng,
        })),
      };
      modifyDeliveryStation(JSON.stringify(data));
    },
    async deliverMultipleSecondary() {
      if (!this.selectedCount || !this.enableClick) return;
      const message =
        '确定后，订单状态将恢复为"待配送"，可为车辆重新下发配送任务';
      const status = await NDialog.confirm({
        message,
        title: '确定批量二次配送吗？',
      });
      this.enableClick = false;
      if (status === 'confirm') {
        const res = await batchSecondDelivery({
          ids: Object.values(this.selectedMap).map((d) => d.id),
        });
        // 成功后返回null
        if (res === null) {
          Toast({
            message: '操作成功',
            className: 'response-toast',
          });
        }
        this.getSelectPageList();
        this.selectedMap = {};
      }
      this.enableClick = true;
    },
    async deliverMultipleOffline() {
      if (!this.selectedCount) return;
      const message = '请确认是否执行【驿站退库】操作？';
      const status = await NDialog.confirm({
        message,
        title: '确定批量线下配送吗？',
        cancelText: '仅取出，不退库',
        okText: '退库',
        showClose: true,
      });

      if (status === 'close') {
        this.initMultipleSelect();
        return;
      } // 点了❌
      const res = await batchOfflineDelivery({
        ids: Object.values(this.selectedMap).map((d) => d.id),
        operationType: status === 'confirm' ? 2 : 1,
      });
      if (res === null) {
        Toast({
          message: '操作成功',
          className: 'response-toast',
        });
      }
      this.getSelectPageList();
      this.selectedMap = {};
    },
    initMultipleSelect() {
      this.multipleSelect = false;
      this.selectedMap = {};
    },
    async cancelMultipleDelivery() {
      if (!this.selectedCount) return;
      const message =
        '确定后，二次配送的订单状态将变为【异常件】，格口不会打开';
      const status = await NDialog.confirm({
        title: '确定取消配送吗？',
        message,
      });
      if (status === 'confirm') {
        await batchCancelDelivery({
          ids: Object.keys(this.selectedMap),
        });
        this.selectedMap = {};
        this.getSelectPageList();
      }
    },
    handleSelectAll(selected) {
      if (selected) {
        this.orderList.forEach((item) => {
          if (!this.selectedMap[item.id]) {
            this.$set(this.selectedMap, item.id, item);
          }
        });
      } else {
        this.selectedMap = {};
      }
    },
    handleItemSelect(item) {
      if (this.selectedMap[item.id]) {
        this.$delete(this.selectedMap, item.id);
      } else {
        this.$set(this.selectedMap, item.id, item);
      }
    },
    enableMulipleSelect(status) {
      this.multipleSelect = status;
      if (status === false) {
        this.selectedMap = {};
      }
    },
    handleBack() {
      window.location.href = 'neolix://interaction';
    },
    handleTab(data) {
      this.queryStatus = data.key;
      if (data.key === 3) this.showSelector = false;
      this.initMultipleSelect();
      this.orderList = [];
      this.emptyDataFlag = true;
      if (this.value) {
        this.$refs.refHeight.scrollTop = 0;
        this.getSelectPageList();
      }
    },
    handleScreen() {
      this.showFlag = !this.showFlag;
    },
    handleSearchEnter() {
      this.initMultipleSelect();
      if (this.value) {
        this.getSelectPageList();
      } else {
        this.orderList = [];
        this.emptyDataFlag = true;
      }
    },
    handleSelectValue() {
      this.showFlag = !this.showFlag;
    },
    handleSelectSet(data) {
      this.selectValue = data.value;
      this.selectValueKey = data.key;
      this.placeholderValue = '请输入' + data.value;
      this.value = '';
      this.showFlag = !this.showFlag;
    },
    getUserId() {
      return sessionStorage.getItem('_userId');
    },

    getSelectPageList() {
      const queryObject = this.queryObject;
      const params = {
        privacyCheck: JSON.parse(queryObject.privacyCheck),
        orderAddressInfoId: queryObject.orderAddressInfoId
          ? queryObject.orderAddressInfoId
          : '',
        rcvStationId: queryObject.stationId ? queryObject.stationId : '',
        queryStatus: Number(this.queryStatus),
        userId: this.getUserId(),
        pageSize: this.pageSize,
        pageNo: 1,
        endDate: moment().locale('zh-cn').format('YYYY-MM-DD'),
        startDate: moment().add(-6, 'd').format('YYYY-MM-DD'),
      };

      if (this.selectValueKey === 1) {
        params.expressNo = this.value;
      } else if (this.selectValueKey === 2) {
        params.rcvPhone = this.value;
      } else if (this.selectValueKey === 3) {
        params.rcvStationName = this.value;
      } else if (this.selectValueKey === 4) {
        params.vinId = this.value;
      }
      selectPageList({
        rcvPhone: '',
        rcvStationName: '',
        vinId: '',
        expressNo: '',
        ...params,
      })
        .then((res) => {
          this.orderList = res.list;
          if (this.queryStatus === 3) {
            this.showSelector = true;
          } else {
            this.showSelector = false;
          }
          this.total = res.total;
          if (res.list.length > 0) {
            this.emptyDataFlag = false;
          } else {
            this.emptyDataFlag = true;
          }
          this.orderList.map((item) => {
            if (item.rcvPhone) {
              item.encryptionUserPhone = item.rcvPhone;
            }
            switch (item.expressStatus) {
              case 0:
                item.color = 'cFC7E36';
                break;
              case 1:
                item.color = 'c436EFF';
                break;
              case 3:
                item.color = 'c00CB95';
                break;
              case 4:
                item.color = 'cFF391B';
                break;
              default:
                item.color = 'c436EFF';
                break;
            }
          });
        })
        .catch(() => {
          this.orderList = [];
          this.emptyDataFlag = true;
        });
    },
    // 打电话
    handlePhone(item) {
      let encryptionUserPhone = item.encryptionUserPhone;
      if (encryptionUserPhone.includes('-')) {
        const phoneItem = encryptionUserPhone.split('-');
        this.phoneNumber = phoneItem[0];
        this.dialogCancel = '暂不拨打';
        this.dialogEnter = '确定拨打';
        this.dialogTitle = '确定拨打电话吗？';
        this.dialogContent = '请在电话拨通后拨转接号' + phoneItem[1];
        this.showDialog = true;
        this.dialogType = 'telphone';
      } else {
        this.phoneNumber = encryptionUserPhone;
        this.telphone();
      }
    },

    // 二次配送
    handleSecondSendOrder(item) {
      if (!this.enableClick) return;
      this.showDialogClose = false;
      this.secondSendOrderCode = item;
      this.dialogTitle = '确定二次配送该快递吗？';
      this.dialogContent =
        '确定后，订单状态将恢复为“待配送”，可为车辆重新下发配送任务';
      this.dialogCancel = '取消';
      this.dialogEnter = '确定';
      this.showDialog = true;
      this.dialogType = 'secondSendOrder';
    },

    // 线下配送
    handleOfflineDelivery(data) {
      // this.offlineDeliveryOrderCode = data.packageNo;
      // this.vinId = data.vehicleVin;
      // this.cabinCode = data.cabinCode;
      // this.dialogTitle = "确定线下配送该快递吗？";
      // this.dialogContent =
      //   "确定后，格口将自动打开，请取出包裹通过其他线下方式完成配送";
      // this.showDialog = true;
      // this.dialogType = "offlineDelivery";
      this.orderDetail = data;
      this.offlineDeliveryOrderCode = data.packageNo;
      this.vinId = data.vehicleVin;
      this.cabinCode = data.cabinCode;
      // 0 - 摩西系统 1 - 小兵系统
      if ([0, 1].includes(data.expressSource)) {
        // if (true) {
        this.showDialog = true;
        this.dialogTitle = '线下配送';
        this.dialogContent = '是否执行【驿站退库】操作？';
        this.dialogCancel = '仅取出，不退库';
        this.dialogEnter = '退库';
        // this.dialogType = "expressOfflineDelivery";

        if (data.expressSource === 0) {
          //摩西
          //如果是摩西则不拉取退库原因，点击退库就直接退库不传原因
          this.dialogType = 'confirmReturn';
        } else {
          this.dialogType = 'expressOfflineDelivery';
          //如果是小兵系统拉取退库原因
          this.getAbnList(data);
        }
      } else {
        this.dialogTitle = '确定线下配送该快递吗？';
        this.dialogContent =
          '确定后，格口将自动打开，请取出包裹通过其他线下方式完成配送';
        this.showDialog = true;
        this.dialogType = 'offlineDelivery';
      }
    },
    // 小兵系统拉取退库原因
    getAbnList(value) {
      // 暂时参数没有
      getAbnList({
        sheetNo: value.expressNo,
        stationId: value.rcvStationId,
        companyCode: value.expressCompanyCode,
        // sheetNo: "1292766926655",
        // stationId: "1",
        // companyCode: "1",
      })
        .then((res) => {
          // this.showDialog = false;
          this.abnList = res;

          this.abnList.map((v, n) => {
            v.check = false;
          });
          this.$forceUpdate();
        })
        .finally(() => {
          // this.showDialog = false;
          this.getSelectPageList();
        });
    },

    //切换退库原因
    switchOption(i) {
      this.abnList.map((v, n) => {
        if (n === i) {
          v.check = true;
          this.confirmReturnCode = v.code;
          this.confirmReturnDesc = v.desc;
        } else {
          v.check = false;
        }
      });
      this.abnList = [...this.abnList];

      // console.log(this.abnList);
    },

    // 取出-退库/不退库
    takeOut(type) {
      console.log('type', type);
      justOpenDoor({
        vin: this.orderDetail.vehicleVin,
        id: this.orderDetail.id,
        // packageNo: this.orderDetail.packageNo, //包裹订单号
        abnCode: this.confirmReturnCode, //异常原因code
        abnDesc: this.confirmReturnDesc, //异常原因
        expressSource: this.orderDetail.expressSource, //面单来源 0--摩西  1--小兵
        operationType: type, //1--仅取出，不退库 2--退库
      })
        .then(() => {
          // this.showDialog = false;
          type === 2 && (this.confirmReturnCode = ''); //置灰确定按钮
          Toast({
            message: '操作成功',
            className: 'response-toast',
          });
          // Message({
          //   title: "操作成功！",
          //   type: "success",
          //   collapsable: false,
          // });
          // console.log(`type == 1 ? '取出不退库成功' : '退库成功'`, res);
        })
        .finally(() => {
          this.showDialog = false;
          this.showDialogAbn = false;
          this.getSelectPageList();
        });
    },

    handleOk() {
      this.showDialog = false;
      if (this.dialogType == 'secondSendOrder') {
        this.secondSendOrder();
      } else if (this.dialogType == 'offlineDelivery') {
        this.offlineDelivery();
      } else if (this.dialogType == 'telphone') {
        this.telphone();
      } else if (this.dialogType === 'expressOfflineDelivery') {
        this.showDialogAbn = true;
        this.dialogTitle = '请选择退库原因';
        // this.dialogContent = "是否执行【驿站退库】操作？";
        this.dialogCancel = '取消';
        this.dialogEnter = '确定退库';
        this.dialogType = 'confirmReturn';
      } else if (this.dialogType === 'confirmReturn') {
        console.log('确定退库');
        this.takeOut(2);
      }
    },

    handleCancel(flag = false) {
      console.log('flag:', flag);
      if (!flag && this.dialogType === 'expressOfflineDelivery') {
        console.log('仅取出，不退库');
        // 仅取出，不退库
        this.takeOut(1);
      } else if (
        !flag &&
        this.dialogType === 'confirmReturn' &&
        this.orderDetail.expressSource === 0
      ) {
        console.log('仅取出，不退库   confirmReturn');
        this.takeOut(1);
      } else {
        this.showDialog = false;
        this.showDialogAbn = false;
        this.confirmReturnCode = '';
      }
    },

    getToken() {
      return sessionStorage.getItem('_token');
    },

    handleSearchCancel() {
      this.$emit('handleSearchCancel');
      // this.$router.push({
      //   path: "myOrderList",
      //   query: {
      //     token: this.getToken(),
      //     userId: this.getUserId(),
      //     queryStatus: this.$route.query.queryStatus,
      //     privacyCheck: this.$route.query.privacyCheck,
      //     tabType: "right",
      //   },
      // });
    },
    // 二次配送订单
    secondSendOrder() {
      this.enableClick = false;
      secondDelivery({
        id: this.secondSendOrderCode.id,
      })
        .then((res) => {
          this.getSelectPageList();
          this.showDialog = false;
          Toast({
            message: '操作成功',
            className: 'response-toast',
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.enableClick = true;
        });
    },
    // 拨打电话
    telphone() {
      window.location.href = 'tel://' + this.phoneNumber;
    },

    // 线下配送
    offlineDelivery() {
      offlineDelivery({
        id: this.orderDetail.id,
        vin: this.vinId,
        cabinCode: this.cabinCode,
      })
        .then((res) => {
          this.getSelectPageList();
          this.showDialog = false;
          Toast({
            message: '操作成功',
            className: 'response-toast',
          });
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.multipleSelectPannel {
  font-size: initial;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: white;
  min-height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  box-sizing: border-box;
  > div {
    &:nth-child(1) {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 0px;
      color: rgba(0, 0, 0, 1);
      font-weight: 600;
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }

      .colorfulNumber {
        color: #426eff;
        display: inline-block;
      }
    }
    &:nth-child(2) {
      display: flex;
      align-items: center;
      div + div {
        margin-left: 12px;
      }

      div {
        padding: 5px 10px;
        border-radius: 16px;
        background: #ffffff;
        border: 1px solid #436eff;
        color: #436eff;
        line-height: normal;
        &.disabled {
          opacity: 0.2;
        }
      }
      .modifyBtn {
        border: 1px solid #436eff;
        color: #436eff;
      }
      .cancelBtn {
        border: 1px solid #ff2d55;
        color: #ff2d55;
      }
    }
  }
}
.bg {
  @include background_color('bg');
}
.mask {
  width: 100%;
  height: calc(100% + 0.8rem);
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  opacity: 0.3;
  /*兼容IE8及以下版本浏览器*/
  filter: alpha(opacity=30);
}

.searchContain {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  z-index: 2;
  .selectList {
    background: #ffffff;
    border-radius: 12px;
    width: 3.02rem;
    height: 4.24rem;
    border: 1px solid red;
    position: absolute;
    top: 120px;
    left: 30px;
    z-index: 1000;
    padding: 0.2rem 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .item {
      padding: 0.16rem 0.55px;
      background: #f1f2f6;
      border-radius: 0.355rem;
      width: 100%;
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      color: #000000;
    }
    .active {
      background: #ecf0ff;
      border: 0.02rem solid rgba(67, 110, 255, 1);
      color: #436eff;
    }
  }
  .searchPanel {
    width: 100%;
    height: 1.92rem;
    display: flex;
    padding: 6px;
    background: #ffffff;
    justify-content: space-between;
  }
  .searchBack {
    width: 0.64rem;
    height: 0.64rem;
  }
  .selectValue {
    height: 0.4rem;
    font-size: 0.28rem;
    color: #000000;
    font-weight: 400;
    // margin-right: 0.18rem;
  }
  .searchUp {
    width: 0.24rem;
    height: 0.24rem;
  }
  .searchSelect {
    width: 26.7%;
    height: 0.64rem;
    display: flex;
    background: #f6f7f8;
    border-radius: 0.12rem;
    align-items: center;
    justify-content: space-around;
    padding: 0 0.14rem;
    overflow: hidden;
  }
  .searchBarInner {
    width: 47.2%;
    height: 0.64rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #f6f7f8;
    border-radius: 0.12rem;
    overflow: hidden;
  }

  .inputWrapper {
    flex-grow: 1;
    width: 55.6%;
    height: 0.64rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #f6f7f8;
    border-radius: 0.08rem;

    .searchIcon {
      width: 0.64rem;
      height: 0.64rem;
      padding: 0.18rem;
    }

    input {
      height: 0.64rem;
      flex-grow: 1;
      font-size: 0.3rem;
      font-weight: 600;
      color: #000000;
      caret-color: #436eff;
      background: #f6f7f8;
      border-radius: 0.12rem;
      overflow: hidden;
      &::placeholder {
        font-size: 0.28rem;
        color: #bfc0cb;
        font-weight: normal;
      }
    }

    .clearIcon {
      width: 0.36rem;
      height: 0.36rem;
      margin-right: 0.16rem;
    }
  }
  .cancelBtn {
    line-height: 0.64rem;
    font-size: 0.32rem;
    color: #000000;
    height: 0.64rem;
  }
  .info {
    display: flex;
    font-size: 0.3rem;
    height: 0.48rem;
    line-height: 0.48rem;
    justify-content: space-between;
    margin: 0.24rem 0.3rem 0 0.24rem;
    .totalNumber {
      color: #fc7e36;
      font-weight: 600;
      font-size: 0.24rem;
    }
    .space {
      width: 1.28rem;
      position: relative;
    }
    img {
      width: 0.48rem;
      height: 0.48rem;
    }
    .selectPic {
    }
    .searchPic {
      margin-left: 0.32rem;
    }
    .multipleSelect {
      width: max-content;
      position: absolute;
      right: 0;
      font-size: 14px;
      font-weight: 400;
      color: rgba(67, 110, 255, 1);
    }
  }
  .active {
    color: #436eff !important;
    span {
      display: inline-block;
      width: 20%;
      height: 0.06rem;
      background: #436eff;
      position: absolute;
      bottom: 0.04rem;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 0.06rem;
    }
  }
  .tabLeft,
  .tabRight {
    width: 50%;
    text-align: center;
    display: inline-block;
    color: #7a7b92;
    position: relative;
  }
  .myOrder {
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.3rem;
    margin: 0 auto;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    text-align: center;
  }

  .web {
    width: 100%;
    height: calc(100% - 0.8rem);
    overflow: scroll;
    padding-bottom: 100px;
    .info {
      display: flex;
      font-size: 0.3rem;
      height: 0.48rem;
      line-height: 0.48rem;
      justify-content: space-between;
      margin: 0.24rem 0.3rem 0.24rem 0.24rem;
      .total {
        color: #9798ab;
        font-size: 0.26rem;
      }
      .totalNumber {
        color: #fc7e36;
        font-weight: 600;
        font-size: 0.26rem;
        margin: 0 0.08rem;
      }
      .space {
        width: 1.28rem;
      }
      img {
        width: 0.48rem;
        height: 0.48rem;
        margin: 0 0.16rem;
      }
    }
    .orderList {
      width: 100%;
      padding-bottom: 0.2rem;
      @include background_color('bg');
      .tip {
        height: 0.93rem;
        line-height: 0.93rem;
        /*margin-bottom: .30rem;*/
        font-size: 0.24rem;
        color: #aeb5ca;
        text-align: center;

        & + .order {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
