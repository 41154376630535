<template>
  <div class="tab">
    <span
      v-for="item in tabList"
      :class="activeKey === item.key ? 'active' : ''"
      :key="item.key"
      class="nomal"
      @click="handleTab(item)"
    >
      {{ item.name }}
      <i v-show="item.abnormalCount > 0" class="readWarning"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "Tab",
  data() {
    return {
      activeKey: this.defaultTab,
    };
  },

  props: {
    tabList: {
      type: Array,
      default: [],
    },
    defaultTab: {
      type: Number,
      default: 0,
    },
  },

  mounted: function () {},

  created: function () {},
  watch: {
    defaultTab: function (value) {
      this.activeKey = value;
    },
  },

  methods: {
    handleTab(data) {
      this.activeKey = data.key;
      this.$emit("handleTab", data);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tab {
  width: 100%;
  height: 1.12rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 2;
  top: 0.79rem;
  left: 0;
  background: #ffffff;
  padding: 0 0.3rem;
  .readWarning {
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 0.1rem;
    background: #ff391b;
    position: absolute;
    left: 1.12rem;
    top: -0.06rem;
    display: inline-block;
  }
  .nomal {
    height: 0.56rem;
    width: 1.4rem;
    line-height: 0.58rem;
    font-size: 0.28rem;
    border-radius: 0.28rem;
    background: #f6f7f8;
    color: #7a7b92;
    text-align: center;
    -webkit-tap-highlight-color: transparent !important;
    position: relative;
  }
  .active {
    color: #ffffff;
    background: #436eff;
    font-weight: 600;
  }
}
</style>
