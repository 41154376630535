export const leftList = [
  {
    key: 0,
    index: 0,
    name: "全部",
    type: "left",
  },
  {
    key: 1,
    index: 1,
    name: "进行中",
    type: "left",
  },
  {
    key: 4,
    index: 2,
    name: "已完成",
    type: "left",
  },
  {
    key: 2,
    index: 3,
    name: "已取消",
    type: "left",
  },
];
export const rightList = [
  {
    key: 0,
    index: 0,
    name: "待配送",
    type: "right",
  },
  {
    key: 1,
    index: 1,
    name: "配送中",
    type: "right",
  },
  {
    key: 2,
    index: 2,
    name: "已完成",
    type: "right",
  },
  {
    key: 3,
    index: 3,
    name: "异常件",
    type: "right",
  },
];
export const selectList = [
  {
    key: 2,
    value: "全部",
  },
  {
    key: 0,
    value: "非隐私面单",
  },
  {
    key: 1,
    value: "隐私面单",
  },
];
