<template>
  <div class="popUp" v-show="showFlag">
    <div
      class="selectList"
      :style="{
        top: styleObj.box.top,
        left: styleObj.box.left,
      }"
    >
      <span
        v-for="item in selectList"
        :key="item.key"
        class="itemContent"
        @click.stop="handleSelect(item)"
      >
        <span class="item" :class="showActiveKey === item.key ? 'active' : ''">
          {{ item.value }}
        </span>
      </span>
    </div>
    <div
      class="triangle"
      :style="{
        top: styleObj.triangle.top,
        left: styleObj.triangle.left,
      }"
    ></div>

    <div class="mask" @click.stop="handleClose"></div>
  </div>
</template>

<script>
export default {
  name: "PopSelect",
  props: {
    defaultValue: {
      type: Number,
      default: 0,
    },
    triangle: {
      type: String,
      default: "",
    },
    styleObj: {
      type: Object,
      default: "",
    },
    selectList: {
      type: Array,
      default: [
        {
          key: 1,
          value: "快递单号",
        },
        {
          key: 2,
          value: "手机号",
        },
        {
          key: 3,
          value: "收货点",
        },
        {
          key: 4,
          value: "车号",
        },
      ],
    },
    showFlag: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showActiveKey() {
      return this.activeKey !== "" ? this.activeKey : this.$props.defaultValue;
    },
  },
  data() {
    return {
      activeKey: "",
    };
  },

  watch: {},

  mounted: function () {},

  methods: {
    handleSelect(data) {
      this.activeKey = data.key;
      this.$emit("handleSelect", data);
    },
    handleClose() {
      this.$emit("handleClose");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.triangle {
  width: 0;
  height: 0;
  border-bottom: 0.12rem solid #ffffff;
  border-right: 0.12rem solid transparent;
  border-left: 0.12rem solid transparent;
  z-index: 1000;
  position: fixed;
}
.left {
  top: 0.7rem;
  left: 1rem;
  position: fixed;
}

.right {
  top: -10.68rem;
  left: 5.9rem;
  position: absolute;
}

.mask {
  width: 100%;
  height: calc(100% + 0.8rem);
  background-color: #000000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  opacity: 0.5;
}
.selectList {
  background: #ffffff;
  border-radius: 12px;
  width: 3.02rem;
  z-index: 1000;
  padding: 0.2rem 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: fixed;
  .itemContent {
    width: 100%;
    height: 0.96rem;
    padding: 0.12rem 0;
    .item {
      padding: 0.16rem 0.55px;
      background: #f1f2f6;
      border-radius: 0.355rem;
      width: 100%;
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      color: #000000;
      display: inherit;
    }
    .active {
      background: #ecf0ff;
      border: 0.02rem solid rgba(67, 110, 255, 1);
      color: #436eff;
      font-weight: 600
    }
  }
}
</style>
